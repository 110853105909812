<template>
  <div class="tab-pane fade" id="formacion" role="tabpanel" aria-labelledby="formacion-tab">
     <div class="course__formacion mb-45">
        <div v-if="programaFormacion && mostrarFormacion">
<!--        <div class="app-header">-->
<!--          <div>-->
<!--            <span v-if="showAllPages">-->
<!--              {{ pageCount }} página(s)-->
<!--            </span>-->

<!--            <span v-else>-->
<!--                  <button :disabled="page <= 1" @click="page&#45;&#45;" style="width: 20px; color: black;">❮</button>-->

<!--                  {{ page }} / {{ pageCount }}-->

<!--                  <button :disabled="page >= pageCount" @click="page++" style="width: 20px; color: black;">❯</button>-->
<!--            </span>-->

<!--            <label class="right">-->
<!--              <input v-model="showAllPages" type="checkbox" id="checkMostrarAllPagesFormacion">-->
<!--              Mostrar todas las páginas-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="app-content" style="height: 500px; overflow-y: auto;">-->
<!--            <vue-pdf-embed-->
<!--              :source="programaFormacion"-->
<!--              ref="pdfRefFormacion"-->
<!--              :page="page"-->
<!--              @rendered="handleDocumentRender"-->
<!--            />-->
<!--        </div>-->
              <vue-pdf-app style="height: 75vh;" :pdf="programaFormacion" theme="light" :key="formacionKey" @pages-rendered="pagesRendered"></vue-pdf-app>
          </div>
          <p v-else class="">No existe información para mostrar</p>
          <br>
     </div>
 </div>
</template>

<script>

// import VuePdfEmbed from "vue-pdf-embed";
import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";

export default {
   name:'FormacionTab',
    components: {
       // VuePdfEmbed,
       VuePdfApp,
    },
    props: {
        programaFormacion: String,
        formacionKey: Number,
        mostrarFormacion: Boolean
    },
    data() {
        return {
            isLoading: true,
            // page: 1,
            // pageCount: 1,
            // showAllPages: false,
        }
    },
    // watch: {
    //   // showAllPages() {
    //   //   this.page = this.showAllPages ? null : 1
    //   // },
    //   formacionKey(){
    //         document.getElementById("checkMostrarAllPagesFormacion").click();
    //   }
    // },
    methods: {
        handleDocumentRender() {
          this.pageCount = this.$refs.pdfRefFormacion.pageCount
        },
        pagesRendered(pdfApp) {
            setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-height"));
          // pdfApp.pdfViewer.currentScaleValue = "0.7"
            setTimeout(function() {
                pdfApp.pdfViewer.currentScaleValue = "0.3"
            }, 500);
            //console.log("pages rendered formaciontab")
            var button_brochure = document.getElementById("formacion-tab-list")
            button_brochure.click()
          // if(this.permiteLoadPdf || this.permiteReloadPdf){
          //     if(this.permiteLoadPdf){
          //         this.permitirReloadPdf()
          //         this.noPermitirLoadPdf()
          //     }
          //     else if(this.permiteReloadPdf){
          //         this.noPermitirReloadPdf()
          //     }
          //     console.log("entra en if de brochure tab")
          //     // this.mostrarBrochureTab()
          //     var button_brochure = document.getElementById("curriculum-tab")
          //     button_brochure.click()
          }
    }
};
</script>
